import React, { useState, useEffect } from "react"
import Papa from "papaparse";
import Alert from 'react-bootstrap/Alert';

const Announcements = () => {
  // Client-side Runtime Data Fetching
  const [data, setData] = useState({ }); 
  useEffect(() => {
    // https://docs.google.com/spreadsheets/d/1nExYzhQSrtG4GekzW3mf19sbkjpGsYnOx7glp6_S4DE/gviz/tq
    // fetch(`https://docs.google.com/spreadsheets/d/e/2PACX-1vTDPPa0A3rV_5hlbfxi88wbKjy_KzfX-2rM4LBFTMyrGFGwxEGU0hB-vA1BZuBVSFrASjJs6WHl3ghs/pub?gid=0&single=true&output=csv`)
    //   .then(response => response.text()) // parse JSON from request
    //   .then(resultData => {
    //     setStarsCount(resultData)
    //     console.log(resultData);
    //   }) 
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTDPPa0A3rV_5hlbfxi88wbKjy_KzfX-2rM4LBFTMyrGFGwxEGU0hB-vA1BZuBVSFrASjJs6WHl3ghs/pub?gid=0&single=true&output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });
      
  }, [])

  const announcementsList = Array.from(data);
  return (
    <div>
    {announcementsList.map((data, index) => (
      <Alert key={index} variant={data.announcementType}>
        <b>{data.announcements}</b>
      </Alert>
    ))}
  </div>
  )

}

export default Announcements