import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import time from '../../content/assets/time.png'

const red = {
  color: '#dc7061',
}
const green = {
  color: '#55ab68',
}
const noborder = {
  borderTop: 'none',
}
const nomargin = {
  marginBottom: '0',
  marginTop: '0',
}
const box = {
  border: '2px solid #005677',
  padding: '10px',
  borderRadius: '3px',
}
const bordercolor = {
  borderColor: '#005677',
}
const greenandbordercolor = {
  color: '#55ab68',
  borderColor: '#005677',
}
const redandbordercolor = {
  color: '#dc7061',
  borderColor: '#005677',
}
const tableimg = {
  maxHeight: '50px',
}

const Times = (props) => (
  <div className="times text-left mt-2 pt-2 mb-5">
    {props.season === "winter" ?
    <table className="table" style={box}>
      <thead>
        <tr>
          <th scope="col" style={bordercolor}>
            <img src={time} style={tableimg} alt='Opening Times logo' />
            {' '}
            Winter Hours ❄️
          </th>
          <th scope="col" style={greenandbordercolor}>
            Opens
          </th>
          <th scope="col" style={redandbordercolor}>
            Closes
          </th>
        </tr>
      </thead>
      <tbody>
      <tr>
        <th scope="row">Monday</th>
        <td colSpan="2">
          <span>Closed</span>, except for <span>Bank Holidays</span>
        </td>
      </tr>
      <tr>
        <th scope="row">Tuesday</th>
        <td colSpan="2"><span>Closed</span></td>
      </tr>
      <tr>
        <th scope="row">Wednesday</th>
        <td colSpan="2"><span>Closed</span></td>
      </tr>
      <tr>
        <th scope="row">Thursday</th>
        <td>10:00</td>
        <td>17:00</td>
      </tr>
      <tr>
        <th scope="row">Friday</th>
        <td>10:00</td>
        <td>17:00</td>
      </tr>
      <tr>
        <th scope="row">Saturday</th>
        <td>9:00</td>
        <td>17:00</td>
      </tr>
      <tr>
        <th scope="row">Sunday</th>
        <td>9:00</td>
        <td>17:00</td>
      </tr>
      {/* <tr>
          <td colSpan="3" className="text-center">
          <Link to="/christmas-opening-times/" className="p-3 btn btn-danger">Christmas Opening Times</Link>
          </td>
        </tr> */}
    </tbody>
    </table> 
    : props.season === "summer" ?
    <table className="table" style={box}>
    <thead>
      <tr>
        <th scope="col" style={bordercolor}>
          <img src={time} style={tableimg} alt='Opening Times logo' />
          {' '}
          Summer Hours ☀️
        </th>
        <th scope="col" style={greenandbordercolor}>
          Opens
        </th>
        <th scope="col" style={redandbordercolor}>
          Closes
        </th>
      </tr>
    </thead>
    <tbody>
    <tr>
      <th scope="row">Monday</th>
      <td colSpan="2">
        <span>Closed</span>, except for <span>Bank Holidays</span>
      </td>
    </tr>
    <tr>
      <th scope="row">Tuesday</th>
      <td>10:00</td>
      <td>17:00</td>
    </tr>
    <tr>
      <th scope="row">Wednesday</th>
      <td>10:00</td>
      <td>21:00</td>
    </tr>
    <tr>
      <th scope="row">Thursday</th>
      <td>10:00</td>
      <td>17:00</td>
    </tr>
    <tr>
      <th scope="row">Friday</th>
      <td>10:00</td>
      <td>17:00</td>
    </tr>
    <tr>
      <th scope="row">Saturday</th>
      <td>9:00</td>
      <td>17:00</td>
    </tr>
    <tr>
      <th scope="row">Sunday</th>
      <td>9:00</td>
      <td>17:00</td>
    </tr>
    <tr>
        </tr>
    </tbody>
    </table>
    : ''
    }
  </div>
)

export default Times
